<div class="panel-body">
  <div *ngIf="isAncient">
    <p>Please contact Bankers Fidelity Life Insurance Co. Customer Service.</p>
    <p>
      <label>Phone:</label> 866.458.4799 <br />
      <label>Email: </label> <a href="mailto:bflclaims@bflic.com">bflclaims@bflic.com</a>
    </p>
  </div>
  <div *ngIf="!isAncient">
    <div class="pull-left">
      <strong>Plan: </strong>
      <select id="PlanSelector" [(ngModel)]="selectedPlan" (ngModelChange)="onChange($event)" name="PlanSelector">
        <option value="PLAN A">Plan A</option>
        <option value="PLAN B">Plan B</option>
        <option value="PLAN C">Plan C</option>
        <option *ngIf="isOld" value="PLAN D">Plan D</option>
        <option *ngIf="isOld" value="PLAN E">Plan E</option>
        <option value="PLAN F">Plan F</option>
        <option value="PLAN G">Plan G</option>
        <option *ngIf="isOld" value="PLAN I">Plan I</option>
        <option *ngIf="isCurrent" value="PLAN K">Plan K</option>
        <option value="PLAN HIGH F">Plan High F</option>
        <option value="PLAN HIGH G">Plan High G</option>
      </select>
    </div>
    <div class="pull-right" style="padding-right: 2%">
      <a id="PlanLink" [hidden]="!selectedPlan" target="_blank" class="alerttext" [href]="claimsUrl">Print in New Window</a>
    </div>
  </div>
  <iframe id="PlanFrame" width="98%" height="390" [src]="claimsUrl | safe" type="application/pdf"></iframe>
</div>
