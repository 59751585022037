import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'dependentName'})
export class DependentNamePipe implements PipeTransform {
  public transform(input: string): string {
    if (!input) {
      return '';
    }
    if (input.indexOf('*') === -1) {
      return '';
    }
    const parts = input.split('*');
    if (parts.length < 2) {
      return '';
    }
    return `${parts[1]} ${parts[0]}`;
  }
}
