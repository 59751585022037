import {Observable ,  BehaviorSubject } from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IPolicy } from './Policy';
import { Claim } from './claim';
import { HttpService } from '../shared/http.service';

@Injectable()
export class PolicyService extends HttpService {
  private policySource = new BehaviorSubject<IPolicy>(null);
  currentPolicy = this.policySource.asObservable();
  constructor(private httpService: HttpClient) {
    super(httpService);
   }

  getPolicy(policyNumber: string): Observable<IPolicy> {
    const url = `${this.serviceURL}Policy/${policyNumber}`;
    return this.httpService.get<IPolicy>(url);
  }

  getClaimsForPolicy(policyNumber: string): Observable<Claim[]> {
    const url = `${this.serviceURL}Policy/${policyNumber}/Claims`;
    return this.httpService.get<Claim[]>(url);
  }

  getClaimsByProvider(policy: IPolicy, providerID: number): Observable<IPolicy> {
    const cutoffDate = new Date();
    cutoffDate.setFullYear(cutoffDate.getFullYear() - 3);
    let url = `${this.serviceURL}Policy/${policy.PolicyNumber}/Claims?$filter=ProviderID eq ${providerID}`;
    url = `${url} and DateFrom ge ${cutoffDate.toISOString().slice(0, 10).replace(/-/g, '')}`;
    return this.httpService.get<Claim[]>(url).pipe(map(claims => {
      policy.Claims = claims;
      for (let i = 0; i < policy.Claims.length; i++) {
        policy.Claims[i].id = this.getRandomId();
        policy.Claims[i].policy = policy;
      }
      return policy;
    }), catchError(this.handleError));
  }

  getClaim(claimId: string): Claim {
    const policy = this.policySource.getValue();
    return policy.Claims.find(claim => {
      return claim.ClaimID === claimId;
    });
  }

  changePolicy(policy: IPolicy) {
    this.policySource.next(policy);
  }

  getCurrentPolicy(): IPolicy {
    const policy = this.policySource.getValue();
    return policy;
  }

  getRandomId(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
  }
}
