<div class="card border-primary">
  <div class="card-header text-white bg-primary h5">
    {{pageTitle}}
  </div>
  <div class="card-body">
    <div>
      <br />
      <a href="https://agent.bflic.com/Library/v1_3-23_BFL%20CF-PUF%20(2-23).pdf" target="_blank">Provider Update Form</a>
    </div>
    <br />
  </div>
</div>
