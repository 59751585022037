<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#fff" type="ball-circus" [fullScreen]="true"></ngx-spinner>
<div class="card border-primary">
  <div class="card-header text-white bg-primary h5">
    {{pageTitle}}
  </div>
  <prov-dialog [(visible)]="showDialog">
    <img src="../../../assets/images/medicareidcard.jpg" alt="Policyowner Identification Card" />
  </prov-dialog>
  <div class="card-body">
    <form class="form-horizontal" novalidate #policySearch="ngForm">
      <div class="form-row">
        <div class="col-lg-3">
          <div [ngClass]="{'has-error': (policyVar.touched || policyVar.dirty) && !policyVar.valid }">
            <label for="PolicyNumber">Policy Number <i class="fa fa-question-circle" aria-hidden="true" (mouseover)="showDialog = !showDialog"></i></label>
            <input class="form-control" id="PolicyNumber" type="text" placeholder="Policy Number" required maxlength="10" name="policynum" [(ngModel)]="policyNum" #policyVar="ngModel" />
            <p class="policyNumberHint mb-2">005-<span style="background-color:#FFFF00">0234567890</span> only enter 10 digit policynumber</p>
            <span class="help-block" *ngIf="(policyVar.touched || policyVar.dirty) && policyVar.errors">
              <span style="color: #a94442;font-size: 14px" *ngIf="policyVar.errors.required">
                Please enter a policy number.
              </span><a style="font-size:x-small; cursor:pointer; color:blue" (click)="showDialog = !showDialog">What is this?</a>
            </span>
          </div>
        </div>
        <div class="col-lg-3">
          <div [ngClass]="{'has-error': (dobVar.touched || dobVar.dirty) && !dobVar.valid }">
            <label for="DateOfBirth">Date of Birth</label>
            <input class="form-control" [textMask]="{mask: mask}" id="DateOfBirth" placeholder="mm/dd/yyyy" required name="dob" [ngModel]="dateOfBirth" (ngModelChange)="dateOfBirth = $event" #dobVar="ngModel" />
            <span class="help-block" *ngIf="(dobVar.touched || dobVar.dirty) && dobVar.errors">
              <span style="color: #a94442;font-size: 14px" *ngIf="dobVar.errors.required">
                Please enter the policy holder's Date of Birth.
              </span>
            </span>
          </div>
        </div>
        <div class="col-lg-3">
          <label></label>
          <button class="btn btn-primary mt-lg-4" type="button" [disabled]="!isValid || loading" (click)="SearchPolicy()">Search</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          {{errorMessage}}
        </div>
      </div>
    </form>
    <div>
      <br />
      <strong>Please address all claims to the following: PO Box 211701 Eagan, MN 55121</strong>
      <br />
      <br />
    </div>
    <div>
      <strong>Learn more about our new ePayment vendor, Zelis&trade;</strong>
      <p>
        Bankers Fidelity has selected Zelis™ Payments (formerly Pay-Plus Solutions, Inc.) as our ePayment vendor to assist us in expediting payment and remittance transactions, as well as complying with PPACA Section 1104.
        To sign up for ePayments using ACH or Virtual Payment Cards, as well as electronic remittances (835, Excel, PDF), please visit <a href="https://bankersfidelity.epayment.center/" target="_blank">bankersfidelity.epayment.center</a>.
        <br /><br />
        For all inquiries regarding payments please email <a href="mailto:help@epayment.center">help@epayment.center</a> or call Zelis at 855-774-4392.
        <br /><br />
        <strong>Please note that depending on the date of service, Explanations of Payments (EOPs) might still be found on our former ePayment vendor, Payspan. Please visit <a href="https://www.payspanhealth.com/nps" target="_blank">www.payspanhealth.com</a></strong>
      </p>
    </div>
    <div>
      <b>Save time and reduce cost by using EDI</b>
      <p>
        Bankers Fidelity now offers Real-Time Eligibility Inquiry and Response 270/271 transactions For assistance with submitting Real-Time transactions, please contact your Practice Management System Vendor.
      </p>
    </div>
  </div>
  <div *ngIf="currentPolicy">
    <ul class="nav nav-tabs blue">
      <li class="nav-item"><a routerLink="policy" routerLinkActive="active" class="nav-link">Policy</a></li>
      <li class="nav-item"><a routerLink="claims" routerLinkActive="active" class="nav-link">Claims</a></li>
      <li class="nav-item"><a routerLink="plandetails" routerLinkActive="active" class="nav-link">Plan Details</a></li>
    </ul>
    <router-outlet></router-outlet>
  </div>
</div>
