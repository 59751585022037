import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './components/application/app.component';
import { LoginModule } from './components/login/login.module';
import { HomeComponent } from './components/home/home';
import { LoginService } from './components/login/login.service';
import { HttpService } from './shared/http.service';
import { MenuComponent } from './components/menu/menu';
import { ShellComponent } from './components/shell/shell';
import { ContactComponent } from './components/contact/contact';
import { FaqComponent } from './components/faq/faq';
import { FormsComponent } from './components/forms/forms';
import { DialogComponent } from './components/dialog/dialog';
import { PolicyService } from './_policyData/policy.service';
import { PolicyComponent } from './components/policy/policy';
import { ClaimsComponent } from './components/claims/claims';
import { TitleCasePipe } from './pipes/title-case.pipe';
import { DependentNamePipe } from './pipes/dependent-name.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSlickgridModule } from 'angular-slickgrid';
import { PrintClaimComponent } from './components/printclaim/printclaim';
import { ClaimResolver } from './_policyData/claim-resolver.service';
import { PlanDetailsComponent } from './components/plandetails/plandetails';
import { DateValueAccessorDirective } from './shared/date-value-accessor';
import { TextMaskModule } from 'angular2-text-mask';
import { MonitoringService } from './app.appInsightsModule';
import { BaseComponent } from './components/base/base';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './shared/auth.interceptor';
import { GoogleAnalyticsService } from './shared/googleanalytics.service';
import { NgxSpinnerModule } from "ngx-spinner";
import { ElmahIoErrorHandler } from './_helpers/elmah-error-handler';
import { NgxCaptchaModule } from 'ngx-captcha';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MenuComponent,
    ShellComponent,
    ContactComponent,
    FaqComponent,
    FormsComponent,
    DialogComponent,
    ClaimsComponent,
    PolicyComponent,
    TitleCasePipe,
    DependentNamePipe,
    SafePipe,
    PrintClaimComponent,
    PlanDetailsComponent,
    DateValueAccessorDirective,
    BaseComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    LoginModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AngularSlickgridModule,
    TextMaskModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    NgxSpinnerModule,
    NgxCaptchaModule
  ],
  providers: [
    HttpService,
    LoginService,
    PolicyService,
    ClaimResolver,
    MonitoringService,
    GoogleAnalyticsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },    
    { provide: ErrorHandler, useClass: ElmahIoErrorHandler }    
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
