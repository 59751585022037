<div class="card border-primary">
  <div class="card-header text-white bg-primary h5">
    {{pageTitle}}
  </div>
  <div class="card-body">
    <ul class="faq-list">
      <li>
        <p class="question">
          <strong>Q: </strong>Are precertifications or second opinions required on any of your plans?
        </p>
        <p class="answer">
          <strong>A: </strong>No
        </p>
      </li>
      <li>
        <p class="question">
          <strong>Q: </strong>What is the time limit on filing Medicare claims?
        </p>
        <p class="answer">
          <strong>A: </strong>Medicare claims must be filed one year from the date of service on the claim.
        </p>
      </li>
      <li>
        <p class="question">
          <strong>Q: </strong>Do you accept faxed claims?
        </p>
        <p class="answer">
          <strong>A: </strong>No
        </p>
      </li>
      <li>
        <p class="question">
          <strong>Q: </strong>What do your Medicare supplement plans cover?
        </p>
        <p class="answer">
          <strong>A: </strong>Our Medicare supplement plans cover Medicare approved expenses.
        </p>
      </li>
    </ul>
  </div>
</div>
