<div class="p-3">
  <div class="row">
    <div class="col-12 h5">Policy Information</div>
  </div>
  <hr class="mt-0" />
  <div class="row">
    <div class="col-sm-4 col-lg-2 policy-header">First Name</div>
    <div class="col">{{ currentPolicy.FirstName | titleCase }}</div>
  </div>
  <div class="row">
    <div class="col-sm-4 col-lg-2 policy-header">Last Name</div>
    <div class="col">{{ currentPolicy.LastName | titleCase }}</div>
  </div>
  <div class="row">
    <div class="col-sm-4 col-lg-2 policy-header">Date of Birth</div>
    <div class="col">{{ currentPolicy.DateOfBirth | date }}</div>
  </div>
  <div class="row">
    <div class="col-sm-4 col-lg-2 policy-header">Age</div>
    <div class="col">{{ currentPolicy.Age }}</div>
  </div>
  <div class="row">
    <div class="col-sm-4 col-lg-2 policy-header">Issue Date</div>
    <div class="col">{{ currentPolicy.PolicyIssueDate | date }}</div>
  </div>
  <div class="row" *ngIf="getCoverageStatus()">
    <div class="col-sm-4 col-lg-2 policy-header">Coverage Status</div>
    <div class="col">{{ currentPolicy.PolicyStatus | titleCase }}</div>
  </div>
  <div class="row" *ngIf="currentPolicy.TerminationDate">
    <div class="col-sm-4 col-lg-2 policy-header">Termed Date</div>
    <div class="col">{{ currentPolicy.TerminationDate | date }}</div>
  </div>
  <div class="row">
    <div class="col-sm-4 col-lg-2 policy-header">Coverage</div>
    <div class="col">{{ (currentPolicy.TypeOfCoverage.trim() === 'MEDICARE SUPPLEMENT' ? currentPolicy.TypeOfCoverage.trim() + ' ' + currentPolicy.PlanDescription : currentPolicy.TypeOfCoverage.trim()) | titleCase }}</div>
  </div>
</div>
