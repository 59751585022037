import { throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import * as Utils from '../shared/utils';

@Injectable()
export class HttpService {

  errorMessage: string;
  serviceURL: string;
  token: string;
  isAuthenticating = false;

  constructor(private http: HttpClient) {
    this.serviceURL = environment.settings.servicesURL;
    if (!this.isServiceAuthenticated()) {
      this.authenticate(environment.settings.authUser, environment.settings.authPassword);
    }
  }

  handleError(error: HttpErrorResponse) {
      this.isAuthenticating = false;
      let errMsg: string;
      if (error.error instanceof ErrorEvent) {
          errMsg = `An error occurred: ${error.error.message}`;
      } else {
          errMsg = `Backend returned code ${error.status}, body was ${error.error}`;
      }
      console.error(errMsg);
      return throwError(errMsg);
  }

  authenticate(username: string, password: string): void {
      this.isAuthenticating = true;
      const authUrl = environment.settings.authURL;
      const httpOptions = {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      };
      const formData = new URLSearchParams();
      formData.set('username', username);
      formData.set('password', password);
      formData.set('grant_type', 'password');
      const body = formData.toString();
      this.http.post<any>(authUrl, body, httpOptions)
          .subscribe(res => {
            if (res && res.access_token) {
              this.setSession(res);
            }
            this.isAuthenticating = false;
          },
          err => this.handleError(err));
  }

  setSession(authResult): void {
    const expiresAt = moment().add(authResult.expires_in, 'second');
    sessionStorage.setItem(Utils.tokenKey, authResult.access_token);
    sessionStorage.setItem(Utils.expiresKey, JSON.stringify(expiresAt.valueOf()));
  }

  public isServiceAuthenticated(): boolean {
    if(this.isAuthenticating) {
      return true;
    }
    if (sessionStorage.getItem(Utils.expiresKey) && sessionStorage.getItem(Utils.tokenKey)) {
      return moment().isBefore(this.getExpiration());
    } else {
      return false;
    }
  }

  getExpiration() {
    const expiration = sessionStorage.getItem(Utils.expiresKey);
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }
}
