<a routerLink="/home/claims"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back to Claims</a>

<h2>Claim Details</h2>
<div class="display-label">
  <label>Policy Number</label>
  <span>{{selectedClaim.policy.PolicyNumber}}</span>
</div>
<div class="display-label">
  <label>First Name</label>
  <span>{{selectedClaim.policy.FirstName | titleCase}}</span>
</div>
<div class="display-label">
    <label>Last Name</label>
    <span>{{selectedClaim.policy.LastName | titleCase}}</span>
</div>
<div class="display-label">
    <label>Date of Birth</label>
    <span>{{selectedClaim.policy.DateOfBirth | date}}</span>
</div>
<div class="display-label">
    <label>Age</label>
    <span>{{selectedClaim.policy.Age}}</span>
</div>
<div class="display-label">
    <label>Issue Date</label>
    <span>{{selectedClaim.policy.PolicyIssueDate | date}}</span>
</div>
<div class="display-label" *ngIf="getCoverageStatus()">
    <label>Coverage Status</label>
    <span>{{selectedClaim.policy.PolicyStatus | titleCase}}</span>
</div>
<div class="display-label">
    <label>Coverage Description</label>
    <span>{{(selectedClaim.policy.TypeOfCoverage.trim() === 'MEDICARE SUPPLEMENT' ? selectedClaim.policy.TypeOfCoverage.trim() + ' ' + selectedClaim.policy.PlanDescription : selectedClaim.policy.TypeOfCoverage.trim()) | titleCase}}</span>
</div>
<div class="display-label" *ngIf="selectedClaim.policy.TerminationDate">
    <label>Termination Date</label>
    <span>{{selectedClaim.policy.TerminationDate | date}}</span>
</div>
<div class="display-label">
    <div *ngIf="selectedClaim.policy.PlanDescription === 'PLAN N' && GetProcedureCodesForOfficeAndERVisits()">
      <label>Copay</label>
      <span>{{selectedClaim.MedicareCopayTaken | currency}}</span>
    </div>
    <div *ngIf="selectedClaim.policy.PlanDescription !== 'PLAN N' && selectedClaim.policy.PlanDescription !== 'PLAN HIGH F'">
      <label>Coninsurance</label>
      <span>{{selectedClaim.MedicareCopayTaken | currency}}</span>
    </div>
</div>
<!--<div class="display-label" *ngIf="selectedClaim.AmountPaid > 0 && selectedClaim.AmountPaid === selectedClaim.BulkCheckAmount">-->
<div class="display-label">
  <div *ngIf="selectedClaim.AmountPaid > 0 && selectedClaim.BulkCheckAmount === 0">
    <label>Amount Paid</label><!--Add buk paid amount paid-->
    <span>{{selectedClaim.AmountPaid | currency}}</span>
  </div>
  <div *ngIf="selectedClaim.BulkCheckAmount > 0">
    <label>Bulk Check Amount Paid</label>
    <span>{{selectedClaim.BulkCheckAmount | currency}}</span>
  </div>
</div>
<div class="display-label" *ngIf="selectedClaim.policy.PlanDescription === 'PLAN HIGH F'">
  <label>Deductible Due</label>
  <span>{{selectedClaim.DeductibleAmountF | currency}}</span>
</div>
<!--<div class="display-label" *ngIf="selectedClaim.policy.PlanDescription === 'PLAN HIGH F'">
  <label>Yearly Deductible Met</label>
  <span style="vertical-align: top">{{selectedClaim.PaidDeductible | currency}}</span>
</div>-->
<div class="display-label">
    <label>Claim Number</label>
    <span>{{selectedClaim.ClaimNumber}}</span>
</div>
<div class="display-label">
    <label>Total Charges</label>
    <span>{{selectedClaim.TotalCharges | currency}}</span>
</div>
<div class="display-label">
  <label>Claim Status</label>
  <span *ngIf="getClaimStatusForZeroPay()">{{selectedClaim.ClaimStatus}}</span>
</div>
<div class="display-label" *ngIf="selectedClaim.Description">
    <label>Description</label>
    <span>{{selectedClaim.Description}}</span>
</div>
<div class="display-label" *ngIf="selectedClaim.policy.TerminationDate">
    <label>Date Paid</label>
    <span>{{selectedClaim.DatePaid | date}}</span>
</div>
<div class="display-label" *ngIf="selectedClaim.policy.TerminationDate">
    <label>Date of Service</label>
    <span>{{selectedClaim.DateOfService.indexOf('-') > 0 ? selectedClaim.DateOfService : (selectedClaim.DateOfService | date)}}</span>
</div>
<div class="display-label" *ngIf="this.selectedClaim.CheckNumber > 0">
    <label>Check Number</label>
    <span>{{selectedClaim.CheckNumber}}</span>
</div>
<div *ngIf="this.selectedClaim.BulkCheckAmount > 0">
  <div class="display-label">
    <label>Check Amount</label>
    <span>{{selectedClaim.BulkCheckAmount | currency}}</span>
  </div>
  <div class="display-label">
      <label>Check Date</label>
      <span>{{selectedClaim.CheckDate | date}}</span>
  </div>
</div>
<div *ngIf="selectedClaim.IsDependent">
  <h2>Dependent Details</h2>
  <div class="display-label">
    <label>Dependent Name</label>
    <span>{{selectedClaim.DependentName | dependentName | titleCase}}</span>
  </div>
  <div class="display-label" *ngIf="selectedClaim.policy.TerminationDate">
    <label>Dependent DOB</label>
    <span>{{selectedClaim.DependentDob | date}}</span>
  </div>
</div>
