import { Component, OnInit } from '@angular/core';
import { Column, GridOption, Formatters, GridExtraUtils, AutoResizeOption, Pagination } from 'angular-slickgrid';
import { Claim } from '../../_policyData/claim';
import { CurrencyFormatter } from '../../shared/utils';
import { IPolicy } from '../../_policyData/policy';
import { PolicyService } from '../../_policyData/policy.service';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import { IProvider } from '../../_security/provider';
import {BaseComponent} from '../base/base';

@Component({
  templateUrl: './claims.html',
  styleUrls: ['./claims.css']
})

export class ClaimsComponent extends BaseComponent implements OnInit {
  columnDefinitions: Column[];
  gridOptions: GridOption;
  dataset: Claim[];
  filteredData: Claim[];
  currentPolicy: IPolicy;
  currentDataView: any;
  showFilters = false;
  allDependents: DependentItem[];
  allProviders: ProviderItem[];

  constructor(private policyService: PolicyService, private router: Router, private loginService: LoginService) {
    super();
   }

  ngOnInit(): void {
    this.policyService.currentPolicy.subscribe(policy => {
      if (policy) {
        this.currentPolicy = policy;
        this.dataset = policy.Claims;
        this.dataset.forEach((claim) => {
          if (claim.ClaimStatus === "PAID") {
            claim.Description = "";
          }
          else if (claim.AmountPaid === 0 && claim.Description.includes("Medicare applied this amount to Part B Deductible")) {
            claim.ClaimStatus = "PROCESSED";
          }
          else if (claim.AmountPaid === 0) {
            claim.ClaimStatus = "PENDING";
          }
          else {
            claim.ClaimStatus;
          }
        });
        this.filteredData = policy.Claims;
        if (this.providers.length > 1) {
          this.allProviders = new Array<ProviderItem>(); 
          this.providers.forEach( (provider) => {
            this.allProviders.push({ name: provider.ProviderName, id: provider.ProviderID, selected: true });
          });
        }
        if (this.dependents.length > 0) {
          this.allDependents = new Array<DependentItem>();
          this.dependents.forEach( (dependent) => {
            this.allDependents.push({ name: dependent, selected: true});
          });
        }
      }
    });
    this.columnDefinitions = [
      { id: 'DateOfService', name: 'Service Date(s)', field: 'DateOfService', sortable: true, width: 175, headerCssClass: 'column-header' },
      { id: 'TotalCharges', name: 'Total Charges', field: 'TotalCharges', sortable: true, width: 100, formatter: CurrencyFormatter, headerCssClass: 'column-header' },
      { id: 'ClaimNumber', name: 'Number', field: 'ClaimNumber', sortable: true, width: 100, headerCssClass: 'column-header' },
      { id: 'AmountPaid', name: 'Total Paid', field: 'AmountPaid', sortable: true, width: 75, formatter: CurrencyFormatter, headerCssClass: 'column-header' },
      { id: 'DatePaid', name: 'Date Processed', field: 'DatePaid', sortable: true, width: 100, formatter: Formatters.dateUs, headerCssClass: 'column-header' },
      { id: 'ClaimStatus', name: 'Status', field: 'ClaimStatus', sortable: true, width: 75, headerCssClass: 'column-header' },
      { id: 'Description', name: 'Description', field: 'Description', sortable: true, width: 225, headerCssClass: 'column-header' }
    ];
    this.gridOptions = {
      enableAutoResize: false,
      enableCellNavigation: true,
      enableGridMenu: false,
      enableColumnPicker: false,
      enableSorting: true,
      enableColumnReorder: false,
      enablePagination: true
    };
  }

  gridReady(grid) {
    grid.onClick.subscribe((e, args) => {
      const column = GridExtraUtils.getColumnDefinitionAndData(args);
      this.router.navigate(['/printclaim/' + column.dataContext.ClaimID]);
    });
  }

  dataviewReady(dataview) {
    this.currentDataView = dataview;
  }

  get providers(): IProvider[] {
    return this.loginService.providers;
  }

  get dependents(): string[] {
    const uniqueNames = [];
    for (let i = 0; i < this.dataset.length; i++) {
      if (this.dataset[i].IsDependent && uniqueNames.indexOf(this.dataset[i].DependentName) === -1) {
        uniqueNames.push(this.dataset[i].DependentName);
      }
    }
    return uniqueNames;
  }

  get selectedProviders(): ProviderItem[] {
    return this.allProviders.filter(provider => provider.selected);
  }

  get selectedDependents(): DependentItem[] {
    if(this.allDependents !== null){
    return this.allDependents.filter(dependent => dependent.selected);
    }else{
      console.log("No Dependents");
      return null;
    }
  }

  filterClaims(): void {
    if(this.selectedDependents !== null){
    this.filteredData = this.dataset.filter(
      Claim => 
        this.selectedDependents.some(dep => dep.name === Claim.DependentName) || this.selectedProviders.some(prov => prov.id === Claim.ProviderID)
    )
    }
    else{
      this.filteredData = this.dataset.filter(
        Claim => this.selectedProviders.some(f => f.id === Claim.ProviderID)
      )
  
    }
  }
 
}

class ProviderItem {
  name: string;
  id: number;
  selected: boolean;
}

class DependentItem {
  name: string;
  selected: boolean;
}
