import { Component } from '@angular/core';
import {BaseComponent} from '../base/base';
@Component({
  templateUrl: './contact.html',
  styleUrls: ['./contact.css']
})

export class ContactComponent extends BaseComponent {
  constructor(){
    super();
  }
  public pageTitle = 'Contact Us';
}
