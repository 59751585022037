<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#fff" type="ball-circus" [fullScreen]="true"></ngx-spinner>
<div class="card border-primary mt-5" style="max-width: 49rem;">
  <div class="card-header text-white bg-primary h5">
    {{pageTitle}}
  </div>
  <div class="card-body">
    <form class="form-horizontal" novalidate (ngSubmit)="login(loginForm)" #loginForm="ngForm" autocomplete="off">
      <div *ngIf="systemError" class="errorLarge">The site is currently down for maintenance. We apologize for this inconvenience. The site will be restored shortly.</div>
      <br />
      <div class="form-group row" [ngClass]="{'has-error': (userNameVar.touched ||
                                          userNameVar.dirty) &&
                                          !userNameVar.valid }">
        <label class="col-lg-2 col-form-label" for="userNameId" style="font-size:13px"><b>Provider/Tax ID</b></label>
        <div class="col-lg-8">
          <input class="form-control"
                 id="userNameId"
                 type="text"
                 placeholder="Provider ID or Tax ID (required)"
                 required
                 ngModel
                 name="userName" #userNameVar="ngModel" />
          <span class="help-block" *ngIf="(userNameVar.touched ||
                                                              userNameVar.dirty) &&
                                                              userNameVar.errors">
            <span style="color: #a94442;font-size: 14px" *ngIf="userNameVar.errors.required">
              You must enter either a providerid or taxid.
            </span>
          </span>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-lg-4 offset-lg-2">
          <span>
            <button class="btn btn-primary"
                    type="submit"
                    style="width:80px;margin-right:10px"
                    [disabled]="!loginForm.valid || loading"
                    (click)="getResponse()">Log In</button>
          </span>
          <span class="glyphicon glyphicon-refresh glyphicon-spin spinner" *ngIf="loading"></span>
        </div>
      </div>
    </form>
    <div class="has-error" *ngIf="errorMessage">{{errorMessage}}</div>
    <div>
      <form [formGroup]="captchaFormGroup">
        <div>
          <ngx-recaptcha2 #captchaElem
                          [siteKey]="siteKey"
                          (expire)="handleExpire()"
                          (success)="handleSuccess($event)"
                          formControlName="recaptcha">
          </ngx-recaptcha2>
        </div>
      </form>
    </div>
  </div>
</div>
