import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'prov-shell',
  templateUrl: './shell.html',
  styleUrls: ['./shell.css']
})
export class ShellComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
}
