import { Component, OnInit, AfterViewInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { LoginService } from './login.service';
import { NgForm, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseComponent } from '../base/base';
import { environment } from '../../../environments/environment';
import { GoogleAnalyticsService } from '../../shared/googleanalytics.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ReCaptcha2Component } from "ngx-captcha";


@Component({
  templateUrl: './login.html',
  styleUrls: ['./login.css'], 
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent extends BaseComponent implements OnInit {
  errorMessage: string;
  pageTitle = 'Provider Log In';
  loading = false;
  systemError: boolean;
  public readonly siteKey = '6LfhlosaAAAAAHDeiYFCk_0iY_wEzUmgfSblwiFR';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  public useGlobalDomain = false;
  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;
  public captchaFormGroup: UntypedFormGroup;

  constructor(private service: LoginService, private router: Router, private googleAnalyticsService: GoogleAnalyticsService, private spinner: NgxSpinnerService, private formBuilder: UntypedFormBuilder, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (!this.service.isServiceAuthenticated()) {
      this.service.authenticate(environment.settings.authUser, environment.settings.authPassword);
    }
    this.captchaFormGroup = this.formBuilder.group({
      recaptcha: ['', [Validators.required]]
    });
  }

  login(loginForm: NgForm) {
    this.spinner.show();
    this.systemError = false;
    if (loginForm && loginForm.valid) {
      this.loading = true;
      const providerId = loginForm.form.value.userName;
      const captchaResponse = this.captchaElem.getResponse();
      this.service.login(providerId).subscribe(isValid => {
        if (isValid && captchaResponse !== '') {
          this.googleAnalyticsService.eventEmitter('login', providerId, 'success');
          this.router.navigate(['/home']);
        } else if (this.service.errorMessage) {
          this.errorMessage = this.service.errorMessage;
          this.googleAnalyticsService.eventEmitter('login', providerId, 'fail');
        } else {
          this.errorMessage = 'Invalid ProviderID or TaxID entered.';
          this.googleAnalyticsService.eventEmitter('login', providerId, 'fail');
        }
        this.loading = false;
        this.spinner.hide();
        if (!captchaResponse) {
          alert('You must complete the cpatcha before you login.');
        }
      },
        err => {
          this.systemError = true;
          this.googleAnalyticsService.eventEmitter('login', providerId, 'fail');
          this.service.handleError(err);
          this.spinner.hide();
        });
    } else {
      this.errorMessage = 'You must enter a valid ProviderID or TaxID.';
    }
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
    this.cdr.detectChanges();
  }

  getResponse(): void {
    const response = this.captchaElem.getResponse();
    if (!response) {
      alert('You must complete the captcha before you login.');
    }
  }
}
