import { Component, OnInit } from '@angular/core';
import { PolicyService } from '../../_policyData/policy.service';
import { IPolicy } from '../../_policyData/policy';
import { environment } from '../../../environments/environment';
import {BaseComponent} from '../base/base';


@Component({
  templateUrl: './plandetails.html',
  styleUrls: ['./plandetails.css']
})

export class PlanDetailsComponent extends BaseComponent implements OnInit {
  currentPolicy: IPolicy;
  claimsUrl: string;
  selectedPlan: string;

  constructor(private policyService: PolicyService) {
    super();
   }

  ngOnInit(): void {
    this.policyService.currentPolicy.subscribe(policy => {
      if(policy){
      this.currentPolicy = policy;
      this.selectedPlan = this.currentPolicy.PlanDescription;
      this.setClaimsUrl();
      }
    });
  }

  get isAncient(): boolean {
    if (!this.currentPolicy && !this.currentPolicy.PolicyIssueDate) {
      return true;
    }
    const ancientDate = new Date(1993, 1, 1);
    return this.currentPolicy.PolicyIssueDate < ancientDate;
  }

  get isOld(): boolean {
    if (this.isAncient) {
      return false;
    }
    const oldDate = new Date(2010, 6, 1);
    return this.currentPolicy.PolicyIssueDate < oldDate;
  }

  get isCurrent(): boolean {
    return !this.isAncient && !this.isOld;
  }

  onChange(newPlan) {
    this.selectedPlan = newPlan;
    this.setClaimsUrl();
  }

  private setClaimsUrl() {
    if (this.selectedPlan) {
      this.claimsUrl = `${environment.settings.libraryURL}${this.isOld ? 'old ' : ''}${this.selectedPlan}.pdf`;
    } else {
      this.claimsUrl = '';
    }
  }
}

