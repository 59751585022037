import {Column, Formatter } from 'angular-slickgrid';

export class Utils {
  public static formatMoney(value: number, decPlaces: number, decSeparator: string, kSeparator: string, moneySymbol: string): string {
    const decimalPlaces = isNaN(decPlaces) ? 2 : Math.abs(decPlaces);
    const decimalSeparator = decSeparator || '.';
    const thousandsSeparator = (typeof kSeparator === 'undefined') ? ',' : kSeparator;
    const currencySymbol = (typeof moneySymbol === 'undefined') ? '$' : moneySymbol;
    const sign = value < 0 ? '-' : '';
    const stringValue: any = Math.abs(value).toFixed(decimalPlaces);
    const beforeDecimal: any = parseInt(stringValue, 10) + '';
    const numberOfThousands = (beforeDecimal.length > 3) ? beforeDecimal.length % 3 : 0;
    return `${currencySymbol}${sign}${numberOfThousands ? beforeDecimal.substr(0, numberOfThousands) + thousandsSeparator : ''}
    ${beforeDecimal.substr(numberOfThousands).replace(/(\d{3})(?=\d)/g, '$1' + thousandsSeparator)}
    ${decimalPlaces ? decimalSeparator + Math.abs(stringValue - beforeDecimal).toFixed(decimalPlaces).slice(2) : ''}`;
  }

  public static convertDateToMMDDYYYY(date: Date): string {
    return [Utils.padDateNumber(date.getDate()), Utils.padDateNumber(date.getMonth() + 1), date.getFullYear()].join('/');
  }

  public static padDateNumber(datePart: number): string {
    return datePart < 10 ? '0' + datePart : datePart + '';
  }
}

export const CurrencyFormatter: Formatter = (row: number, cell: number, value: any, columnDef: Column, dataContext: any): string => {
  if (value === null || value === '' || !(value > 0)) {
    return '$' + Number();
  } else {
    return Utils.formatMoney(Number(value), 2, '.', ',', '$');
  }
};

export const tokenKey = 'id_token';
export const expiresKey = 'expires_in';

