import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from '../components/login/login.service';


@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private service: LoginService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.checkLoggedIn();
    }

    checkLoggedIn(): boolean {
      if (this.service.isAuthenticated()) {
        return true;
      }

      this.router.navigate(['/login']);
      return false;
    }
}
