import { Component, OnInit } from '@angular/core';
import { Claim } from '../../_policyData/claim';
import { ActivatedRoute } from '@angular/router';
import { PolicyService } from '../../_policyData/policy.service';
import {BaseComponent} from '../base/base';


@Component({
  templateUrl: './printclaim.html',
  styleUrls: ['./printclaim.css']
})

export class PrintClaimComponent extends BaseComponent implements OnInit {
  selectedClaim: Claim;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.selectedClaim = this.route.snapshot.data['claim'];
  }

  GetProcedureCodesForOfficeAndERVisits(): boolean {
    if (this.selectedClaim.ProcedueCode === "99201")
      return true;
    else if (this.selectedClaim.ProcedueCode === "99202")
      return true;
    else if (this.selectedClaim.ProcedueCode === "99203")
      return true;
    else if (this.selectedClaim.ProcedueCode === "99204")
      return true;
    else if (this.selectedClaim.ProcedueCode === "99205")
      return true;
    else if (this.selectedClaim.ProcedueCode === "99211")
      return true;
    else if (this.selectedClaim.ProcedueCode === "99212")
      return true;
    else if (this.selectedClaim.ProcedueCode === "99213")
      return true;
    else if (this.selectedClaim.ProcedueCode === "99214")
      return true;
    else if (this.selectedClaim.ProcedueCode === "99215")
      return true;
    else if (this.selectedClaim.ProcedueCode === "99281")
      return true;
    else if (this.selectedClaim.ProcedueCode === "99282")
      return true;
    else if (this.selectedClaim.ProcedueCode === "99283")
      return true;
    else if (this.selectedClaim.ProcedueCode === "99284")
      return true;
    else if (this.selectedClaim.ProcedueCode === "99285")
      return true;
    else
      return false;
  }

  getClaimStatusForZeroPay() {
    if (this.selectedClaim.AmountPaid === 0) 
      this.selectedClaim.ClaimStatus = "PROCESSED";
    return this.selectedClaim.ClaimStatus;
  }
  getCoverageStatus() {
    if (this.selectedClaim.policy.PolicyStatus === "Cancelled" || this.selectedClaim.policy.PolicyStatus === "Terminated")
      this.selectedClaim.policy.PolicyStatus = "Termed";
    return this.selectedClaim.policy.PolicyStatus;
  }
}
