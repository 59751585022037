import { Component } from '@angular/core';
//import { FileService } from '../../_policyData/file.service';
import { BaseComponent } from '../base/base';
//import { saveAs } from 'file-saver';

@Component({
  templateUrl: './forms.html',
  styleUrls: ['./forms.css']
})

export class FormsComponent extends BaseComponent {
  constructor(/*private fileService: FileService*/) {
    super();
  }
  public pageTitle = 'Forms';
  //download() {
  //  this.fileService.downloadFile.subscribe(Response: any) => {
  //    let blob: any = new Blob([response], { type: 'text/json; charset=utf-8' });
  //    const url = window.URL.createObjectURL(blob);
  //    saveAs(blob, 'ProviderUpdate.pdf');
  //  }
  //}
}
