import { Component, OnInit } from '@angular/core';
import { PolicyService } from '../../_policyData/policy.service';
import { IPolicy } from '../../_policyData/policy';
import { BaseComponent } from '../base/base';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
@Component({
  templateUrl: './policy.html',
  styleUrls: ['./policy.css']
})

export class PolicyComponent extends BaseComponent implements OnInit {
  currentPolicy: IPolicy;
  

  constructor(private policyService: PolicyService, private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.policyService.currentPolicy.subscribe(policy => this.currentPolicy = policy);
    if (this.currentPolicy.PolicyStatus === 'Cancellation' && moment() < moment(this.currentPolicy.TerminationDate))
      this.currentPolicy.PolicyStatus = 'Cancellation requested, effective ' + moment(this.currentPolicy.TerminationDate).format("MMM D, YYYY");
  }
  getCoverageStatus() {
    if (this.currentPolicy.PolicyStatus === "Cancelled" || this.currentPolicy.PolicyStatus === "Terminated")
      this.currentPolicy.PolicyStatus = "Termed";
    return this.currentPolicy.PolicyStatus;
  }
}
