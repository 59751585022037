import { NgModule } from '@angular/core';
import { LoginComponent } from './login';
import { AuthGuard } from '../../_security/auth.guard';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxCaptchaModule } from 'ngx-captcha';



@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    NgxCaptchaModule
  ],
  declarations: [
    LoginComponent
  ],
  providers: [AuthGuard]
})
export class LoginModule { }
