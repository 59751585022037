export const environment = {
  settings: {
    production: true,
    servicesURL: 'https://ds.bflic.com/api/',
    libraryURL: 'https://agent.bflic.com/Library/',
    authURL: 'https://ds.bflic.com/Token',
    authUser: 'provideruser',
    authPassword: 'u53rPr0v1der!',
    appInsights:{
      instrumentationKey: '7ca11e52-065e-4135-944c-b56aa6269231'
    },
    demoProviderId: '470687317',
    demoPolicyNumber: '2170302329',
    demoBirthOfDate: '05/06/1930',
    applicationName: 'Provider',
    elmahIOLogId: '9fd75aa8-973a-4f4e-a80e-55898b8e4d33'
  }
};
