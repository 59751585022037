<div class="card border-primary">
  <div class="card-header text-white bg-primary h5">
    {{pageTitle}}
  </div>
  <div class="card-body text-center">
    <div class="h4">Phone</div>
    <div>
      <span class="phone-label">Toll-Free: </span> <span>866.458.7499</span>
    </div>
    <div>
      <span class="phone-label">Local: </span> <span>404.266.5720</span>
    </div>
    <div class="h4 pt-3">Address</div>
    <div>
      <strong>For Medicare Supplement Claims:</strong><br />
      Bankers Fidelity Life Insurance Co.<br />
      PO Box 211701<br />
      Eagan, MN 55121
    </div>
    <br/>
    <div>
      <strong>For any other claims:</strong><br />
      Bankers Fidelity Life Insurance Co.<br />
      PO Box 105185<br />
      Atlanta, GA 30348
    </div>
  </div>
</div>
