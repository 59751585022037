import { ErrorHandler, Injectable } from '@angular/core';
import Elmahio from 'elmah.io.javascript/dist/elmahio';
import { environment } from '../../environments/environment';

@Injectable()
export class ElmahIoErrorHandler implements ErrorHandler {
  logger: any;
  constructor() {
    this.logger = new Elmahio({
      apiKey: '95fb084e878d4f7f9d00f72588e03a3c',
      logId: environment.settings.elmahIOLogId,
      application: environment.settings.applicationName,
      filter: function () {
        return false;
      }
    }).on('message', function (msg) {
      msg.user = sessionStorage.getItem('currentProvider') !== null && JSON.parse(sessionStorage.getItem('currentProvider')).length > 0 ? JSON.parse(sessionStorage.getItem('currentProvider'))[0].ProviderID : "";
    });
  }
  handleError(error) {
    if (error && error.message)
      this.logger.error(error.message, error);
    else
      this.logger.error('Error in application', error);
  }
}
