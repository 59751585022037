import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PolicyService } from './policy.service';
import { Claim } from './claim';

@Injectable()
export class ClaimResolver implements Resolve<Claim> {

  constructor(private policyService: PolicyService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Claim {
    const id = route.paramMap.get('id');
    return this.policyService.getClaim(id);
  }
}
