import { Component, ReflectiveInjector } from '@angular/core';
import { MonitoringService } from '../../app.appInsightsModule';

@Component({
  template: ''
})
export class BaseComponent {

private MonitoringService: MonitoringService;

  constructor() {
    const injector = ReflectiveInjector.resolveAndCreate([
      MonitoringService
    ]);
    this.MonitoringService = injector.get(MonitoringService);
    this.logNavigation();
  }

  private logNavigation() {
    this.MonitoringService.logPageView();
  }
}
