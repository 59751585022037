// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  settings: {
    production: false,
    servicesURL: 'https://dsstaging.bflic.com/api/',
    libraryURL: 'https://vendor.bflic.com/Library/',
    authURL: 'http://dsstaging.bflic.com/Token',
    authUser: 'provideruser',
    authPassword: 'u53rPr0v1der!',
    appInsights: {
      instrumentationKey: ''
    },
    demoProviderId: '470687317',
    demoPolicyNumber: '2170302329',
    demoBirthOfDate: '05/06/1930',
    applicationName: 'ProviderStaging',
    elmahIOLogId: '108bbc28-3389-41ed-b2f9-dd768dfa2138'
  }
};
