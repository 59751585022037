import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login';
import { HomeComponent } from './components/home/home';
import { AuthGuard } from './_security/auth.guard';
import { ShellComponent } from './components/shell/shell';
import { ContactComponent } from './components/contact/contact';
import { FaqComponent } from './components/faq/faq';
import { FormsComponent } from './components/forms/forms';
import { MenuComponent } from './components/menu/menu';
import { PolicyComponent } from './components/policy/policy';
import { ClaimsComponent } from './components/claims/claims';
import { PrintClaimComponent } from './components/printclaim/printclaim';
import { ClaimResolver } from './_policyData/claim-resolver.service';
import { PlanDetailsComponent } from "./components/plandetails/plandetails";

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'printclaim/:id', resolve: { claim: ClaimResolver }, component: PrintClaimComponent, canActivate: [AuthGuard] },
  {
    path: '', component: ShellComponent, canActivate: [AuthGuard], children: [
      {
        path: 'home', component: HomeComponent, children: [
          { path: 'policy', component: PolicyComponent },
          { path: 'claims', component: ClaimsComponent },
          { path: 'plandetails', component: PlanDetailsComponent },
          { path: '**', component: PolicyComponent }
        ]
      },
      { path: 'contact', component: ContactComponent },
      { path: 'faq', component: FaqComponent },
      { path: 'forms', component: FormsComponent },
      { path: '**', component: HomeComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes /*, { enableTracing: true }*/, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
