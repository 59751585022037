import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IProvider } from '../../_security/provider';
import { HttpService } from '../../shared/http.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoginService extends HttpService {
  providerKey = 'currentProvider';
  get currentProvider(): IProvider {
    let user: IProvider[];
    const provider = sessionStorage.getItem(this.providerKey);
    if (provider !== 'undefined' && provider) {
      user = JSON.parse(provider);
      return user[0];
    }
    return null;
  }

  get providers(): IProvider[] {
    let providers: IProvider[];
    const storedData = sessionStorage.getItem(this.providerKey);
    if (storedData !== 'undefined') {
      providers = JSON.parse(storedData);
    }
    return providers;
  }

  constructor(private httpService: HttpClient) {
    super(httpService);
  }

  isAuthenticated(): boolean {
    return !!this.currentProvider;
  }

  login(userId: string): Observable<boolean> {
    if (!this.isServiceAuthenticated()) {
      this.authenticate(environment.settings.authUser, environment.settings.authPassword);
    }
    return this.getProviderById(userId).pipe(map(provider => {
      if (provider && provider.length > 0) {
        sessionStorage.setItem(this.providerKey, JSON.stringify(provider));
      }
      return this.isAuthenticated();
    }, catchError(this.handleError)));
  }

  logout(): void {
    sessionStorage.removeItem(this.providerKey);
  }

  private getProviderById(userId: string): Observable<IProvider[]> {
    if (userId.toUpperCase().trim() === environment.settings.authUser.toUpperCase())
      userId = environment.settings.demoProviderId;
    return this.httpService.get<IProvider[]>(`${this.serviceURL}Provider/${userId}`);
  }
}
