import { Component } from '@angular/core';
import {BaseComponent} from '../base/base';
@Component({
  templateUrl: './faq.html',
  styleUrls: ['./faq.css']
})

export class FaqComponent extends BaseComponent {
  constructor(){
    super();
  }
  public pageTitle = 'Frequently Asked Questions';
}
