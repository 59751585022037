
import {distinctUntilChanged} from 'rxjs/operators';
import { Component } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import {MonitoringService} from '../../app.appInsightsModule';


declare var gtag: Function;

@Component({
  selector: 'prov-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Provider Portal';
  loading = true;
  private currentRoute:string;

  constructor(private router: Router, private MonitoringService:MonitoringService) {
      router.events.subscribe((routerEvent: Event) => {
          this.checkRouterEvent(routerEvent);
      });
  }

  ngOnInit() {
    this.router.navigate(['']);
    this.router.events.pipe(distinctUntilChanged((previous: any, current: any) => {
        // Subscribe to any `NavigationEnd` events where the url has changed
        if(current instanceof NavigationEnd) {
            return previous.url === current.url;
        }
        return true;
    })).subscribe((x: any) => {
        gtag('config', 'UA-34092614-6', {'page_path': x.url});
    });
}


  checkRouterEvent(routerEvent: Event): void {
      if (routerEvent instanceof NavigationStart) {
          this.loading = true;
      }

      if (routerEvent instanceof NavigationEnd ||
          routerEvent instanceof NavigationCancel ||
          routerEvent instanceof NavigationError) {
          this.loading = false;
      }
  }
}
