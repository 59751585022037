import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login/login.service';
import { PolicyService } from '../../_policyData/policy.service';

@Component({
  selector: 'prov-menu',
  templateUrl: './menu.html'
})
export class MenuComponent implements OnInit {
  pageTitle = 'Provider Portal';
  userName: string;

  constructor(private router: Router, private loginService: LoginService, private policyService: PolicyService) { }

  ngOnInit() {
    this.userName = this.loginService.currentProvider.ProviderName;
  }

  logOut(): void {
    this.policyService.changePolicy(null);
    this.loginService.logout();
    this.router.navigate(['/login']);
  }
}
