<prov-dialog [(visible)]="showFilters">
  <div id="DialogFilterPanel" style="height: 400px; width: 800px;">
    <div class="filter-locations" *ngIf="providers.length > 1">
      <h4>Locations</h4>
      <div style="margin-right: 25px;">
        <div *ngFor="let provider of allProviders">
          <input type="checkbox" name="SelectedProviders" [ngModelOptions]="{standalone: true}" [(ngModel)]="provider.selected" [id]="'sp' + provider.id" (change)="filterClaims()" />
          <label [for]="'sp' + provider.id">{{provider.name}}</label>
        </div>
      </div>
    </div>
    <div class="filter-dependents" *ngIf="dependents.length > 0">
      <h4>Dependents</h4>
      <div style="margin-right: 25px;">
        <div *ngFor="let dependent of allDependents">
          <input type="checkbox" name="SelectedDependents" [ngModelOptions]="{standalone: true}" [(ngModel)]="dependent.selected" [id]="'dn' + dependent" (change)="filterClaims()" />
          <label [for]="'dn' + dependent">{{dependent}}</label>
        </div>
      </div>
    </div>
  </div>
</prov-dialog>
<div class="panel-body">
  <div style="min-height: 500px">
    <div class="grid-header" style="width: 1075px;">
      <label>Claims</label>
      <span class="alerttext">**Click on an individual claim line for details. Pending Claims are paid out every Friday.</span>
      <i class="fa fa-search pt-1 pr-1" style="float:right" title="Toggle search panel" *ngIf="dependents.length > 0 || providers.length > 1" (click)="showFilters = !showFilters"></i>
      <angular-slickgrid gridId="DataGrid"
                         [columnDefinitions]="columnDefinitions"
                         [gridOptions]="gridOptions"
                         [dataset]="filteredData"
                         [gridHeight]="475"
                         [gridWidth]="1075"
                         class="claimgrid"
                         (gridChanged)="gridReady($event)"
                         (dataviewChanged)="dataviewReady($event)">
      </angular-slickgrid>
    </div>
  </div>
</div>
